import { useAuthState } from '@shared/react';
import { createContext, useContext, useEffect } from 'react';
import ReactGA from 'react-ga4';
import { ANALYTICS_EVENT_NAMES, ANALYTICS_EVENTS } from '../utils/constants';
import { BasicProps } from '../utils/types';

const AnalyticsContext = createContext({
  trackEvent: (eventName: ANALYTICS_EVENT_NAMES, errorMessage?: string) => {},
  trackPageView: (url: string) => {},
  setUserProperties: (properties: Record<string, any>) => {},
});

interface AnalyticsProviderProps extends BasicProps {
  analyticsId: string;
}

export const AnalyticsProvider = (props: AnalyticsProviderProps) => {
  const { children, analyticsId } = props;

  const { userId, isMentor, isAdmin } = useAuthState();

  useEffect(() => {
    if (analyticsId) {
      try {
        ReactGA.initialize(analyticsId);
      } catch (error) {
        console.log('failed to initialize analytics: ', error);
      }
    }
  }, [analyticsId]);

  useEffect(() => {
    const getUserRole = () => {
      if (isAdmin) {
        return 'Admin';
      }

      if (isMentor) {
        return 'Mentor';
      }

      return 'Student';
    };

    if (userId) {
      setUserProperties({ user_id: userId, user_role: getUserRole() });
    }
  }, [userId, isMentor, isAdmin]);

  const trackEvent = (eventName: ANALYTICS_EVENT_NAMES, label?: string) => {
    ReactGA.event({
      category: ANALYTICS_EVENTS[eventName].category,
      action: ANALYTICS_EVENTS[eventName].action,
      label: label ?? ANALYTICS_EVENTS[eventName]?.label,
    });
  };

  const trackPageView = (url: string) => {
    ReactGA.send({ hitType: 'pageview', page: url });
  };

  const setUserProperties = (properties: Record<string, any>) => {
    ReactGA.set(properties);
  };

  return (
    <AnalyticsContext.Provider value={{ trackEvent, trackPageView, setUserProperties }}>
      {children}
    </AnalyticsContext.Provider>
  );
};

export const useAnalytics = () => useContext(AnalyticsContext);
