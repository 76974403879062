import Accordion from '@components/V4/Accordion';
import { MEETING_STATUSES, MeetingStatus } from '@shared/common';
import { MeetingWithDuration } from '@shared/types/DTOs/EnrollmentsDTO';
import { convertSecondsToTime, getAttendanceTimes } from 'apps/agora/src/utils/helpers';
import moment from 'moment';
import MeetingDetail from './MeetingDetail';

interface SessionMeetingProps {
  meeting: MeetingWithDuration;
  name: string;
  isAccordionExpanded?: boolean;
}

const SessionMeeting = ({ meeting, name, isAccordionExpanded }: SessionMeetingProps) => {
  const mentorWaitingRoomAttendance = getAttendanceTimes(meeting.mentor?.attendance?.status, true);
  const studentWaitingRoomAttendance = getAttendanceTimes(
    meeting.students[0]?.attendance?.status,
    true
  );

  const mentorMeetingAttendance = getAttendanceTimes(meeting.mentor?.attendance?.status, false);
  const studentMeetingAttendance = getAttendanceTimes(
    meeting.students[0]?.attendance?.status,
    false
  );

  return (
    <Accordion
      title={
        <p className="font-raleway font-medium font-xs text-white">
          {name} Details <span className="font-bold">({MEETING_STATUSES[meeting.status]})</span>
        </p>
      }
      defaultIsOpen={isAccordionExpanded}
      titleWrapperClassName="bg-surfaceHover p-2"
      className="flex m-2 max-h-[200px] laptop:max-h-[540px]"
    >
      <div className="flex flex-col gap-2">
        <div className="flex justify-between gap-4">
          <div className="flex flex-col gap-2 w-1/2">
            <MeetingDetail name="Meeting ID:" value={meeting._id} showCopyBtn />

            <MeetingDetail
              name="Meeting Creation Date:"
              value={meeting.createdAt ? moment(meeting.createdAt).format('DD.MM.YYYY') : 'n/a'}
            />

            <MeetingDetail
              name="Meeting Scheduled Date:"
              value={
                meeting.startDate ? moment(meeting.startDate).format('DD.MM.YYYY, HH:mm') : 'n/a'
              }
            />
            <MeetingDetail name="Meeting Status:" value={MEETING_STATUSES[meeting.status]} />
          </div>

          <div className="flex flex-col gap-2 w-1/2">
            <MeetingDetail
              name="Meeting Start:"
              value={meeting.startTime ? moment(meeting.startTime).format('DD MMM, HH:mm') : 'n/a'}
            />
            <MeetingDetail
              name="Meeting End:"
              value={meeting.endTime ? moment(meeting.endTime).format('DD MMM, HH:mm') : 'n/a'}
            />
            {!!meeting.duration && (
              <MeetingDetail
                name="Meeting Duration:"
                value={convertSecondsToTime(meeting.duration ?? 0)}
              />
            )}
            <MeetingDetail
              name="Billable Time:"
              value={
                meeting?.billableTime === 0 ? '0m' : convertSecondsToTime(meeting?.billableTime)
              }
            />
          </div>
        </div>

        <div className="flex flex-col gap-2">
          <MeetingDetail name="Mentor in Waiting Room:" value={mentorWaitingRoomAttendance} />

          <MeetingDetail name="Student in Waiting Room:" value={studentWaitingRoomAttendance} />
          <MeetingDetail
            name="Mentor in Meeting:"
            duration={convertSecondsToTime(meeting.mentorAttendanceDuration?.meeting)}
            value={mentorMeetingAttendance}
          />
          <MeetingDetail
            name="Student in Meeting:"
            duration={convertSecondsToTime(meeting.studentAttendanceDuration?.meeting)}
            value={studentMeetingAttendance}
          />

          {meeting.status === MeetingStatus.NO_SHOW && (
            <MeetingDetail name="NoShow Reason:" value={meeting.endMeetingReason ?? 'n/a'} />
          )}
        </div>
      </div>
    </Accordion>
  );
};

export default SessionMeeting;
