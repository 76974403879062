import { ReactComponent as ArrowIcon } from '@assets/icons/V4/arrow-icon.svg';
import IconButton from '@components/V4/IconButton';
import Tooltip from '@components/V4/Tooltip';
import { useAnalytics } from 'apps/agora/src/contexts/AnalyticsContext';
import { ANALYTICS_EVENT_NAMES } from 'apps/agora/src/utils/constants';
import { useHistory } from 'react-router-dom';

interface SessionDetailsNavigationArrowsProps {
  prevSession?: string;
  nextSession?: string;
}

const SessionDetailsNavigationArrows = (props: SessionDetailsNavigationArrowsProps) => {
  const { prevSession, nextSession } = props;

  const history = useHistory();

  const { trackEvent } = useAnalytics();

  const nextSessionButtonHandler = () => {
    if (!nextSession) return;

    trackEvent(ANALYTICS_EVENT_NAMES.SESSION_NAVIGATION_ARROW_CLICK);

    history.push(`/session-details/${nextSession}`);
  };

  const prevSessionButtonHandler = () => {
    if (!prevSession) return;

    trackEvent(ANALYTICS_EVENT_NAMES.SESSION_NAVIGATION_ARROW_CLICK);

    history.push(`/session-details/${prevSession}`);
  };

  return (
    <div className="flex gap-4">
      <Tooltip text="Previous Session">
        <IconButton
          variant="secondary"
          className="flex items-center justify-center w-10 h-10"
          icon={<ArrowIcon className="-rotate-90 w-4 h-4" />}
          onClick={prevSessionButtonHandler}
          isDisabled={!prevSession}
        />
      </Tooltip>

      <Tooltip text="Next Session">
        <IconButton
          variant="secondary"
          className="flex items-center justify-center w-10 h-10"
          icon={<ArrowIcon className="rotate-90 w-4 h-4" />}
          onClick={nextSessionButtonHandler}
          isDisabled={!nextSession}
        />
      </Tooltip>
    </div>
  );
};

export default SessionDetailsNavigationArrows;
