import StepBanner from '@modules/Account/Profile/components/ProfileBanner/StepBanner';
import { useContext, useState } from 'react';
import { MainProfileContext } from '../../../AccountProfile';
import { useDeleteProfileEntry } from '../../../hooks/useDeleteProfileEntry';
import { EducationalCardProps } from '../../../types';
import ProfileMainSection from '../../ResumeSection';
import ResumeTabEducationForm from './ResumeTabEducationForm';
import ResumeTabEducationItem from './ResumeTabEducationItem';

const ResumeEducationSection = (props: EducationalCardProps) => {
  const { userId, eduList, isMentorProfile } = props;

  const { isMentorViewer } = useContext(MainProfileContext);

  const [isAddNewItem, setIsAddNewItem] = useState(false);

  const [deleteEntryHandler] = useDeleteProfileEntry({
    userId,
    profileProperty: 'educationalBackground',
  });

  const addNewItemCancelHandler = () => {
    setIsAddNewItem(false);
  };

  return (
    <ProfileMainSection
      title="Higher Education"
      onClick={() => setIsAddNewItem((prev) => !prev)}
      isButtonActive={isAddNewItem}
    >
      {isAddNewItem && (
        <ResumeTabEducationForm
          isModeAddNew
          onCancel={addNewItemCancelHandler}
          setIsAddNewItem={setIsAddNewItem}
          userId={userId}
        />
      )}

      {eduList?.map((school) => (
        <ResumeTabEducationItem
          key={school._id}
          userId={userId}
          schoolDetails={school}
          onDeleteEntry={deleteEntryHandler}
        />
      ))}

      {!isAddNewItem && !eduList?.length && (!isMentorViewer || isMentorProfile) && (
        <StepBanner stepOption={'missingUniversity'} onClick={() => setIsAddNewItem(true)} />
      )}
    </ProfileMainSection>
  );
};

export default ResumeEducationSection;
