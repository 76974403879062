import './styles/global.less';
import './styles/global.scss';
import './styles/mobile-global.scss';
import { Auth0Provider } from '@auth0/auth0-react';
import {
  AUTH0_AUDIENCE,
  AUTH0_CLIENT_ID,
  AUTH0_CONNECTION,
  AUTH0_DOMAIN,
  BASE_URL,
  ENVIRONMENT,
  G_ANALYTICS_ID,
} from '@shared/frontendEnv';
import { WebProvider } from '@shared/react';
import axios from 'axios';
import * as ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import Router from './Router';
import { TranslationProvider } from './TranslationContext';
import { AnalyticsProvider } from './contexts/AnalyticsContext';

axios.defaults.baseURL = BASE_URL;

const loadEnv = async () => {
  const response = await fetch('/config.json');
  if (!response.ok) {
    throw new Error(`Failed to load environment variables: ${response.statusText}`);
  }
  const env = await response.json();

  window.env = env;

  console.log('Environment variables loaded:', env);
  return env;
};

const initApp = async () => {
  let env: any = {};
  try {
    env = await loadEnv();
  } catch (error) {
    env = {
      ENVIRONMENT,
      BASE_URL,
      AUTH0_DOMAIN,
      AUTH0_CLIENT_ID,
      AUTH0_AUDIENCE,
      AUTH0_CONNECTION,
      G_ANALYTICS_ID,
    };
  }

  axios.defaults.baseURL = env.BASE_URL;

  if (env.ENVIRONMENT === 'production') {
    console.log = function () {};
    console.warn = function () {};
    console.error = function () {};
    console.info = function () {};
    console.debug = function () {};
  }

  const redirect_uri = `${env.BASE_URL}/home`;

  ReactDOM.render(
    <Auth0Provider
      domain={env.AUTH0_DOMAIN ?? ''}
      clientId={env.AUTH0_CLIENT_ID ?? ''}
      authorizationParams={{
        redirect_uri,
        audience: env.AUTH0_AUDIENCE ?? '',
        connection: env.AUTH0_CONNECTION ?? '',
        scope: 'openid profile email offline_access',
      }}
      useRefreshTokens={true}
      cacheLocation="localstorage"
    >
      <TranslationProvider>
        <WebProvider environment={env.ENVIRONMENT}>
          <AnalyticsProvider analyticsId={env.G_ANALYTICS_ID}>
            <BrowserRouter
              getUserConfirmation={() => {
                /* Empty callback to block the default browser prompt */
              }}
            >
              <Router />
            </BrowserRouter>
          </AnalyticsProvider>
        </WebProvider>
      </TranslationProvider>
    </Auth0Provider>,
    document.getElementById('root')
  );
};

initApp();
