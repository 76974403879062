import { ReactComponent as TranscriptIcon } from '@assets/icons/V4/session-details/transcript-icon.svg';
import Button from '@components/V4/Button';
import { useAuthState } from '@shared/react';
import { MeetingTranscript } from '@shared/types/DTOs/EnrollmentsDTO';
import { useAnalytics } from 'apps/agora/src/contexts/AnalyticsContext';
import { ANALYTICS_EVENT_NAMES } from 'apps/agora/src/utils/constants';
import {
  getTimestampFromMeetingTime,
  groupTranscriptsByAuthor,
} from 'apps/agora/src/utils/helpers';
import { COLORS } from 'apps/agora/src/v3/global';
import React, { useState } from 'react';
import SessionDetailsEmptyState from '../components/SessionDetailsEmptyState';
import SessionDetailsLayoutCard from '../components/SessionDetailsLayoutCard';

interface SessionDetailsTranscriptProps {
  transcript?: MeetingTranscript[];
  accentColor: string;
  startDate?: string;
}

const SessionDetailsTranscript = (props: SessionDetailsTranscriptProps) => {
  const { transcript, accentColor, startDate } = props;

  const { trackEvent } = useAnalytics();

  const [showEntireTranscript, setShowEntireTranscript] = useState(false);

  const showEntireTranscriptButtonHandler = () => {
    setShowEntireTranscript((prev) => {
      if (!prev) {
        trackEvent(ANALYTICS_EVENT_NAMES.SESSION_SHOW_ENTIRE_TRANSCRIPT_BUTTON_CLICK);
      }

      return !prev;
    });
  };

  const { fullName } = useAuthState();

  const nrOfTranscriptGroups = transcript?.length;

  const transcriptList = showEntireTranscript ? transcript : transcript?.slice(0, 5);

  const groupedTranscripts = groupTranscriptsByAuthor(transcriptList ?? []);

  return (
    <SessionDetailsLayoutCard
      className="gap-8"
      title="Transcript"
      icon={<TranscriptIcon className="text-white" />}
    >
      {groupedTranscripts?.length ? (
        <>
          <div className="flex flex-col gap-6">
            {groupedTranscripts.map((transcriptGroup, groupIndex) => (
              <div key={groupIndex} className="flex">
                <div className="flex flex-col gap-3">
                  {transcriptGroup.messages.map((msg, msgIndex) => (
                    <div key={`${msg.startTime}-${msgIndex}`} className="flex flex-col gap-2">
                      <div className="flex gap-2">
                        {msgIndex === 0 && (
                          <p
                            className="text-customGrey text-xs font-bold"
                            style={{
                              color:
                                transcriptGroup.user !== fullName
                                  ? accentColor
                                  : COLORS.TEXT_SECONDARY,
                            }}
                          >
                            {transcriptGroup.user}
                          </p>
                        )}
                        <p className="text-customGrey text-xs">
                          {msg?.startTime && startDate
                            ? getTimestampFromMeetingTime(msg?.startTime, startDate)
                            : 'n/a'}
                        </p>
                      </div>

                      <p className="text-white text-xs leading-5">{msg.text}</p>
                    </div>
                  ))}
                </div>
              </div>
            ))}
          </div>
          {nrOfTranscriptGroups && nrOfTranscriptGroups > 5 && (
            <div className="flex justify-center">
              <Button
                variant="secondary"
                buttonText={showEntireTranscript ? 'Show less' : 'Show Entire Transcript'}
                onClick={showEntireTranscriptButtonHandler}
              />
            </div>
          )}
        </>
      ) : (
        <SessionDetailsEmptyState
          icon={<TranscriptIcon className="text-customGrey" />}
          text="No Transcript History."
        />
      )}
    </SessionDetailsLayoutCard>
  );
};

export default SessionDetailsTranscript;
