import FeedbackModal, { Feedback } from '@modules/Mentor/components/FeedbackModal/FeedbackModal';
import { Meeting } from '@shared/common';
import { Tooltip } from 'antd';
import React, { useState } from 'react';
import { COLORS } from '../../../../../v3/global';
import './meeting-card-buttons.styles.scss';
import { useAnalytics } from 'apps/agora/src/contexts/AnalyticsContext';
import { ANALYTICS_EVENT_NAMES } from 'apps/agora/src/utils/constants';

interface JoinButtonProps {
  meeting: Meeting;
  sessionName: string;
  afterSave?: () => void;
}

export interface Explanations {
  explanation0: string;
  explanation1: string;
  explanation2: string;
}

const GiveFeedbackButtonMentor: React.FC<JoinButtonProps> = ({
  meeting,
  afterSave,
  sessionName,
}) => {
  const [showModal, setShowModal] = useState(false);
  const [feedback, setFeedback] = useState<Feedback>({});

  const { trackEvent } = useAnalytics();

  const giveFeedbackClickHandler = () => {
    setShowModal(true);
    trackEvent(ANALYTICS_EVENT_NAMES.MEETING_GIVE_FEEDBACK_BUTTON_CLICK);
  };

  return (
    <div>
      {showModal ? (
        <FeedbackModal
          meetingId={meeting._id}
          feedback={feedback}
          setFeedback={setFeedback}
          title={`${sessionName} / ${meeting.students[0].fullName}`}
          onClose={() => setShowModal(false)}
        />
      ) : null}

      <Tooltip
        zIndex={1}
        color="transparent"
        placement="topRight"
        trigger="hover"
        title={
          <div className="feedback-button-tooltip">
            <span
              style={{
                color: COLORS.TEXT_SECONDARY,
              }}
            >
              Give Feedback
            </span>
          </div>
        }
      >
        <span onClick={giveFeedbackClickHandler} className="button-icon-container">
          <div className="feedback-button-icon" />
        </span>
      </Tooltip>
    </div>
  );
};

export default GiveFeedbackButtonMentor;
