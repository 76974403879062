import { ReactComponent as BiographyIcon } from '@assets/icons/profile-icons/biography-icon.svg';
import { ReactComponent as EditIcon } from '@assets/icons/profile-icons/edit-icon.svg';
import Button from '@components/V4/Button';
import IconButton from '@components/V4/IconButton';
import TextArea from '@components/V4/Inputs/Textarea';
import { zodResolver } from '@hookform/resolvers/zod';
import { useSaveUserProfile } from '@shared/react';
import { useAnalytics } from 'apps/agora/src/contexts/AnalyticsContext';
import useToast from 'apps/agora/src/hooks/useToast';
import { ANALYTICS_EVENT_NAMES } from 'apps/agora/src/utils/constants';
import { useContext, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useQueryClient } from 'react-query';
import { z } from 'zod';
import { MainProfileContext } from '../AccountProfile';
import StudentAsideInfo from './ProfileAsideInfo';

interface ProfileAsideBiographyProps {
  biography: string;
  userId: string;
  hasEditPermissions: boolean;
}

const contactSchema = z.object({
  biography: z.string().min(0, { message: 'Please enter a valid biography.' }),
});

type FormData = z.infer<typeof contactSchema>;

const ProfileAsideBiography = (props: ProfileAsideBiographyProps) => {
  const { biography, userId, hasEditPermissions } = props;
  const [isEditMode, setIsEditMode] = useState(false);
  const [showToast] = useToast();
  const queryClient = useQueryClient();

  const { isExternalViewer } = useContext(MainProfileContext);

  const { trackEvent } = useAnalytics();

  const {
    register,
    handleSubmit,
    trigger,
    setValue,
    getValues,
    formState: { errors, touchedFields },
    reset,
  } = useForm<FormData>({
    resolver: zodResolver(contactSchema),
    reValidateMode: 'onBlur',
    mode: 'onBlur',
    defaultValues: {
      biography,
    },
  });

  const editButtonHandler = () => {
    setIsEditMode((prev) => !prev);
    reset({ biography });
  };

  const { mutate: updateUserProfile, isLoading } = useSaveUserProfile(userId, {
    onSuccess: async () => {
      trackEvent(
        isExternalViewer
          ? ANALYTICS_EVENT_NAMES.STUDENT_PROFILE_CHANGE_BY_MENTOR
          : ANALYTICS_EVENT_NAMES.PROFILE_CHANGE
      );

      showToast({
        variant: 'success',
        messageTitle: 'Success',
        messageBody: 'Successfully updated your profile.',
      });
      await queryClient.invalidateQueries('/users/:id/profile');
      editButtonHandler();
    },
    onError: () => {
      showToast({
        variant: 'error',
        messageTitle: 'Error',
        messageBody: 'Profile could not be saved.',
      });
    },
  });

  const onSubmit = async (data: FormData) => {
    const isValid = await trigger();
    if (isValid) {
      updateUserProfile({
        biography: data.biography,
      });
    }
  };

  const touchAllFields = (fields: Record<keyof FormData, string>) => {
    Object.keys(fields).forEach((key) => {
      setValue(key as keyof FormData, getValues()[key as keyof FormData], {
        shouldTouch: true,
        shouldValidate: true,
      });
    });
  };

  const submitHandler = (e: React.MouseEvent<HTMLButtonElement>) => {
    const formData = getValues();

    touchAllFields(formData);

    handleSubmit(onSubmit)(e);
  };

  useEffect(() => {
    if (isEditMode) {
      const formData = getValues();

      touchAllFields(formData);
    }
  }, [isEditMode]);

  return isEditMode ? (
    <div className="flex gap-6 py-4 bg-surfaceHover px-6 rounded-xl">
      <div className="flex flex-col gap-4 w-full">
        <div className="flex gap-4 w-full">
          <BiographyIcon className="min-w-4 min-h-4 w-4 h-4 text-customPrimary" />
          <h3 className="font-raleway font-bold">Biography</h3>
        </div>
        <div className="flex flex-col gap-6">
          <h4 className="text-customPrimary font-raleway text-xsm font-bold">Edit Biography</h4>

          <TextArea
            id="biography"
            label="Biography"
            className="min-h-32"
            isRequired
            isTouched={touchedFields.biography}
            isValid={!errors.biography}
            errorText={errors.biography?.message}
            {...register('biography', { required: true })}
          />
        </div>

        <div className="flex justify-end gap-4">
          <Button buttonText="Cancel" variant="secondary" onMouseDown={editButtonHandler} />
          <Button
            buttonText="Save"
            isLoading={isLoading}
            variant="primary"
            onMouseDown={submitHandler}
          />
        </div>
      </div>
    </div>
  ) : (
    <div className="flex gap-6 py-4 px-6">
      <div className="flex gap-4 w-full">
        <BiographyIcon className="min-w-4 min-h-4 w-4 h-4 text-customPrimary" />
        <div className="flex flex-col gap-6">
          <h3 className="font-raleway font-bold">Biography</h3>
          <StudentAsideInfo value={biography} />
        </div>
      </div>
      {hasEditPermissions && (
        <IconButton
          onClick={editButtonHandler}
          className="min-h-6 min-w-6"
          icon={<EditIcon className="min-w-3 min-h-3" />}
        />
      )}
    </div>
  );
};

export default ProfileAsideBiography;
