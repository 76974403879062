import AgoraBigSelector from '@components/V3/Utils/InputsV3/AgoraBigSelector';
import AgoraDatePicker from '@components/V3/Utils/InputsV3/AgoraDatePicker';
import Input from '@components/V4/Inputs/Input';
import TextArea from '@components/V4/Inputs/Textarea';
import { zodResolver } from '@hookform/resolvers/zod';
import { AppliedUniversity } from '@shared/common';
import { useSaveUserProfile } from '@shared/react';
import { useAnalytics } from 'apps/agora/src/contexts/AnalyticsContext';
import useToast from 'apps/agora/src/hooks/useToast';
import { ANALYTICS_EVENT_NAMES } from 'apps/agora/src/utils/constants';
import moment from 'moment';
import { useContext, useEffect } from 'react';
import { useController, useForm } from 'react-hook-form';
import { useQueryClient } from 'react-query';
import { z } from 'zod';
import { MainProfileContext } from '../../../AccountProfile';
import { applicationStatuses, degreeTypes } from '../../../constants';
import { ExamFormProps } from '../../../types';
import StudentFormWrapper from '../../ProfileFormWrapper';

interface UniversitiesSectionFormProps extends ExamFormProps {
  goalData?: AppliedUniversity;
}

const formDataSchema = z.object({
  name: z.string().min(1, { message: 'Please enter a valid exam name.' }),
  major: z.string().min(1, { message: 'Please enter a valid major name.' }),
  degree: z.string().min(1, { message: 'Please enter a valid degree type.' }),
  applicationDeadline: z.string().min(1, { message: 'Please enter a valid date.' }),
  status: z.string().min(1, { message: 'Please enter a valid status type.' }),
  pros: z.string().optional().default(''),
  cons: z.string().optional().default(''),
  academicRequirements: z.string().optional().default(''),
});

type FormData = z.infer<typeof formDataSchema>;

const UniversitiesSectionForm = (props: UniversitiesSectionFormProps) => {
  const {
    isModeAddNew,
    userId,
    contentClassName,
    className,
    goalData,
    setIsEditMode,
    setIsAddNewItem,
    onCancel,
  } = props;

  const { isExternalViewer } = useContext(MainProfileContext);

  const [showToast] = useToast();
  const query = useQueryClient();
  const { trackEvent } = useAnalytics();

  const {
    register,
    handleSubmit,
    trigger,
    setValue,
    control,
    getValues,
    formState: { errors, touchedFields },
    reset,
  } = useForm<FormData>({
    resolver: zodResolver(formDataSchema),
    reValidateMode: 'onBlur',
    mode: 'onBlur',
    defaultValues: {
      name: goalData?.university.name,
      major: goalData?.major.name,
      degree: goalData?.degree,
      applicationDeadline: goalData?.applicationDeadline ?? '',
      status: goalData?.status,
      pros: goalData?.pros ?? '',
      cons: goalData?.cons ?? '',
      academicRequirements: goalData?.academicRequirements,
    },
  });

  const { field: degreeField, fieldState: degreeFieldState } = useController({
    name: 'degree',
    control,
  });

  const { field: statusField, fieldState: statusFieldState } = useController({
    name: 'status',
    control,
  });

  const { field: applicationDeadlineField, fieldState: applicationDeadlineFieldState } =
    useController({
      name: 'applicationDeadline',
      control,
    });

  const editButtonHandler = () => {
    setIsEditMode?.(false);
    setIsAddNewItem?.(false);
    reset();
  };

  const { mutate: updateUserProfile, isLoading } = useSaveUserProfile(userId, {
    onSuccess: async () => {
      trackEvent(
        isExternalViewer
          ? ANALYTICS_EVENT_NAMES.STUDENT_PROFILE_CHANGE_BY_MENTOR
          : ANALYTICS_EVENT_NAMES.PROFILE_CHANGE
      );

      showToast({
        variant: 'success',
        messageTitle: 'Success',
        messageBody: 'Successfully updated your profile.',
      });
      await query.invalidateQueries('/users/:id/profile');
      editButtonHandler();
    },
    onError: () => {
      showToast({
        variant: 'error',
        messageTitle: 'Error',
        messageBody: 'Profile could not be saved.',
      });
    },
  });

  const onSubmit = async (data: FormData) => {
    const isValid = await trigger();
    if (isValid) {
      const universityData: AppliedUniversity = {
        _id: goalData?._id,
        university: {
          name: data.name,
          type: 'University',
        },
        major: {
          name: data.major,
        },
        applicationDeadline: data.applicationDeadline,
        academicRequirements: data.academicRequirements ?? '',
        degree: data.degree,
        pros: data.pros,
        cons: data.cons,
        status: data.status,
      };

      updateUserProfile({ appliedUniversities: [universityData] });
    }
  };

  const touchAllFields = (fields: Record<keyof FormData, string>) => {
    Object.keys(fields).forEach((key) => {
      setValue(key as keyof FormData, getValues()[key as keyof FormData], {
        shouldTouch: true,
        shouldValidate: true,
      });
    });
  };

  const submitHandler = (e: React.MouseEvent<HTMLButtonElement>) => {
    const formData = getValues();

    touchAllFields(formData);

    handleSubmit(onSubmit)(e);
  };

  useEffect(() => {
    if (!isModeAddNew) {
      const formData = getValues();

      touchAllFields(formData);
    }
  }, []);

  return (
    <StudentFormWrapper
      title="University"
      isModeAddNew={isModeAddNew}
      onCancel={onCancel}
      className={className}
      contentClassName={contentClassName}
      icon={isModeAddNew && <p className="text-xsm text-customPrimary font-raleway font-bold">#</p>}
      isLoading={isLoading}
      editButtonHandler={editButtonHandler}
      submitHandler={submitHandler}
    >
      <Input
        type="text"
        id={'university'}
        placeholder={''}
        label={'University'}
        isRequired
        isTouched={touchedFields.name}
        isValid={!errors.name}
        errorText={errors.name?.message}
        autofocus
        {...register('name')}
      />
      <div className="gap-6 laptop:flex">
        <Input
          type="text"
          id={'major'}
          placeholder={''}
          label={'Major'}
          isRequired
          isTouched={touchedFields.major}
          isValid={!errors.major}
          errorText={errors.major?.message}
          {...register('major')}
        />
        <AgoraBigSelector
          value={degreeField.value}
          onSelect={(value) => degreeField.onChange(value)}
          options={degreeTypes?.map((degree) => ({
            label: degree,
            value: degree,
          }))}
          onBlur={degreeField.onBlur}
          labelKey="label"
          valueKey="value"
          label="Degree"
          isValid={!degreeFieldState.error}
          isTouched={degreeFieldState.isTouched}
          errorText={degreeFieldState.error?.message}
          isRequired
        />
      </div>
      <div className="gap-6 laptop:flex">
        <AgoraDatePicker
          value={
            applicationDeadlineField.value ? moment(applicationDeadlineField.value) : undefined
          }
          onChange={(value) =>
            applicationDeadlineField.onChange(moment(value).format('YYYY-MM-DD'))
          }
          onBlur={applicationDeadlineField.onBlur}
          allowClear={false}
          isRequired
          label="Application Deadline"
          isValid={!applicationDeadlineFieldState.error}
          isTouched={applicationDeadlineFieldState.isTouched}
          errorText={applicationDeadlineFieldState.error?.message}
        />
        <AgoraBigSelector
          value={statusField.value}
          onSelect={(value) => statusField.onChange(value)}
          options={applicationStatuses?.map((status) => ({
            label: status.label,
            value: status.value,
          }))}
          onBlur={statusField.onBlur}
          labelKey="label"
          valueKey="value"
          label="Status"
          isValid={!statusFieldState.error}
          isTouched={statusFieldState.isTouched}
          errorText={statusFieldState.error?.message}
          isRequired
        />
      </div>
      <div className="gap-6 laptop:flex">
        <Input type="text" id={'pros'} placeholder={''} label={"Pro's"} {...register('pros')} />
        <Input type="text" id={'cons'} placeholder={''} label={"Con's"} {...register('cons')} />
      </div>
      <TextArea
        id={'academicRequirements'}
        placeholder={''}
        label={'Academic Requirements'}
        {...register('academicRequirements')}
      />
    </StudentFormWrapper>
  );
};

export default UniversitiesSectionForm;
