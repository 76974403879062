import { ReactComponent as ExamIcon } from '@assets/icons/profile-icons/exam-icon.svg';
import AgoraDatePicker from '@components/V3/Utils/InputsV3/AgoraDatePicker';
import Input from '@components/V4/Inputs/Input';
import TextArea from '@components/V4/Inputs/Textarea';
import { zodResolver } from '@hookform/resolvers/zod';
import { LanguageExam } from '@shared/common';
import { useSaveUserProfile } from '@shared/react';
import { useAnalytics } from 'apps/agora/src/contexts/AnalyticsContext';
import useToast from 'apps/agora/src/hooks/useToast';
import { ANALYTICS_EVENT_NAMES } from 'apps/agora/src/utils/constants';
import moment from 'moment';
import { useContext, useEffect } from 'react';
import { useController, useForm } from 'react-hook-form';
import { useQueryClient } from 'react-query';
import { z } from 'zod';
import { MainProfileContext } from '../../../AccountProfile';
import { ExamFormProps } from '../../../types';
import StudentFormWrapper from '../../ProfileFormWrapper';

interface UpcomingExamsFormProps extends ExamFormProps {
  examData?: LanguageExam;
}

const formDataSchema = z.object({
  exam: z.string().min(1, { message: 'Please enter a valid exam name.' }),
  targetGrade: z.string().optional().default(''),
  prevGrade: z.string().optional().default(''),
  date: z.string().min(1, { message: 'Please enter a valid date.' }),
  areasOfImprovement: z.string().optional().default(''),
});

type FormData = z.infer<typeof formDataSchema>;

const UpcomingExamsForm = (props: UpcomingExamsFormProps) => {
  const {
    isModeAddNew,
    userId,
    contentClassName,
    className,
    examData,
    setIsEditMode,
    setIsAddNewItem,
    onCancel,
  } = props;

  const { isExternalViewer } = useContext(MainProfileContext);

  const [showToast] = useToast();
  const query = useQueryClient();
  const { trackEvent } = useAnalytics();

  const {
    register,
    handleSubmit,
    trigger,
    setValue,
    control,
    getValues,
    formState: { errors, touchedFields },
    reset,
  } = useForm<FormData>({
    resolver: zodResolver(formDataSchema),
    reValidateMode: 'onBlur',
    mode: 'onBlur',
    defaultValues: {
      exam: examData?.exam,
      targetGrade: examData?.targetGrade,
      prevGrade: examData?.prevGrade,
      date: examData?.date ?? '',
      areasOfImprovement: examData?.areasOfImprovement,
    },
  });

  const { field: dateField, fieldState: dateFieldState } = useController({
    name: 'date',
    control,
  });

  const editButtonHandler = () => {
    setIsEditMode?.(false);
    setIsAddNewItem?.(false);
    reset();
  };

  const { mutate: updateUserProfile, isLoading } = useSaveUserProfile(userId, {
    onSuccess: async () => {
      trackEvent(
        isExternalViewer
          ? ANALYTICS_EVENT_NAMES.STUDENT_PROFILE_CHANGE_BY_MENTOR
          : ANALYTICS_EVENT_NAMES.PROFILE_CHANGE
      );

      showToast({
        variant: 'success',
        messageTitle: 'Success',
        messageBody: 'Successfully updated your profile.',
      });
      await query.invalidateQueries('/users/:id/profile');
      editButtonHandler();
    },
    onError: () => {
      showToast({
        variant: 'error',
        messageTitle: 'Error',
        messageBody: 'Profile could not be saved.',
      });
    },
  });

  const onSubmit = async (data: FormData) => {
    const isValid = await trigger();
    if (isValid) {
      const languageExam: Partial<LanguageExam> = {
        ...data,
        _id: examData?._id,
      };

      updateUserProfile({
        languageExams: [languageExam],
      });
    }
  };

  const touchAllFields = (fields: Record<keyof FormData, string>) => {
    Object.keys(fields).forEach((key) => {
      setValue(key as keyof FormData, getValues()[key as keyof FormData], {
        shouldTouch: true,
        shouldValidate: true,
      });
    });
  };

  const submitHandler = (e: React.MouseEvent<HTMLButtonElement>) => {
    const formData = getValues();

    touchAllFields(formData);

    handleSubmit(onSubmit)(e);
  };

  useEffect(() => {
    if (!isModeAddNew) {
      const formData = getValues();

      touchAllFields(formData);
    }
  }, []);

  return (
    <StudentFormWrapper
      title="Exam"
      isModeAddNew={isModeAddNew}
      onCancel={onCancel}
      className={className}
      contentClassName={contentClassName}
      submitHandler={submitHandler}
      isLoading={isLoading}
      editButtonHandler={editButtonHandler}
      icon={isModeAddNew && <ExamIcon className="min-w-5 w-5" />}
    >
      <Input
        type="text"
        id={'exam'}
        placeholder={''}
        label={'Exam Name'}
        isRequired
        isValid={!errors.exam?.message}
        isTouched={touchedFields.exam}
        errorText={errors.exam?.message}
        autofocus
        {...register('exam')}
      />
      <div className="gap-6 laptop:flex">
        <Input
          type="text"
          id={'targetGrade'}
          placeholder={''}
          label={'Target Score'}
          {...register('targetGrade')}
        />
        <Input
          type="text"
          id={'prevGrade'}
          placeholder={''}
          label={'Last Score'}
          {...register('prevGrade')}
        />
      </div>
      <AgoraDatePicker
        value={dateField.value ? moment(dateField.value) : undefined}
        onChange={(value) => dateField.onChange(moment(value).format('YYYY-MM-DD'))}
        disabledDate={(date) => date < moment(moment().format('YYYY-MM-DD'))}
        onBlur={dateField.onBlur}
        allowClear={false}
        isRequired
        label="Date"
        isValid={!dateFieldState.error}
        isTouched={dateFieldState.isTouched}
        errorText={dateFieldState.error?.message}
      />

      <TextArea
        id={'areasOfImprovement'}
        placeholder={''}
        className="hover:bg-opacity-50 focus:bg-opacity-50"
        label={'Ares Of Improvement'}
        {...register('areasOfImprovement')}
      />
    </StudentFormWrapper>
  );
};

export default UpcomingExamsForm;
