import Input from '@components/V4/Inputs/Input';
import Select from '@components/V4/Select/Select';
import { zodResolver } from '@hookform/resolvers/zod';
import { countryList, supportedLanguages, timezones } from '@shared/constants';
import { useSaveUserProfile } from '@shared/react';
import useToast from 'apps/agora/src/hooks/useToast';
import { phoneRegex } from 'apps/agora/src/utils/constants';
import { BasicProps } from 'apps/agora/src/utils/types';
import { useContext, useEffect } from 'react';
import { useController, useForm } from 'react-hook-form';
import { z } from 'zod';
import SessionModalFormWrapper from '../../SessionModalFormWrapper';
import { StudentDetailsPageContext } from '../StudentDetailsPage';
import AgoraDatePicker from '@components/V3/Utils/InputsV3/AgoraDatePicker';
import moment from 'moment';

interface StudentDetailsFormProps extends BasicProps {
  onCloseEditMode: () => void;
}

const contactSchema = z.object({
  email: z.string().email({ message: 'Invalid email address' }),
  phone: z.string().regex(phoneRegex, 'Please enter a valid phone number'),
  parentMail: z.string().email().min(1, { message: 'Invalid email address' }),
  parentPhone: z.string().regex(phoneRegex, 'Please enter a valid phone number'),
  parentName: z.string().min(1, { message: 'Please enter a valid name' }),
  country: z.string().min(1, { message: 'Please select a country' }),
  city: z.string().min(1, { message: 'Please select a city' }),
  timezone: z.string().min(1, { message: 'Please select a timezone' }),
  language: z.string().min(1, { message: 'Please select a language' }),
  graduationYear: z.number().min(1, { message: 'Please select a graduation year' }),
});

type FormData = z.infer<typeof contactSchema>;

const StudentDetailsForm = (props: StudentDetailsFormProps) => {
  const { onCloseEditMode } = props;

  const { student } = useContext(StudentDetailsPageContext);

  const studentId = student?._id ?? '';

  const [showToast] = useToast();

  const { mutate: updateUserProfile } = useSaveUserProfile(studentId, {
    onSuccess: async () => {
      showToast({
        variant: 'success',
        messageTitle: 'Success',
        messageBody: 'Successfully updated your profile.',
      });
      onCloseEditMode();
    },
    onError: () => {
      showToast({
        variant: 'error',
        messageTitle: 'Error',
        messageBody: 'Profile could not be saved.',
      });
    },
  });

  const {
    register,
    handleSubmit,
    trigger,
    setValue,
    control,
    getValues,
    formState: { errors, touchedFields },
  } = useForm<FormData>({
    resolver: zodResolver(contactSchema),
    reValidateMode: 'onBlur',
    mode: 'onBlur',
    defaultValues: {
      email: student?.email,
      phone: student?.phoneNumber,
      parentMail: student?.parentData?.email,
      parentPhone: student?.parentData?.phoneNumber,
      parentName: student?.parentData?.fullName,
      country: student?.country,
      city: student?.city,
      timezone: student?.timezone,
      language: student?.preferredLanguage,
      graduationYear: student?.graduationYear,
    },
  });

  const { field: countryField, fieldState: countryFieldState } = useController({
    name: 'country',
    control,
  });

  const { field: timezoneField, fieldState: timezoneFieldState } = useController({
    name: 'timezone',
    control,
  });

  const { field: languageField, fieldState: languageFieldState } = useController({
    name: 'language',
    control,
  });

  const { field: graduationYearField, fieldState: graduationYearFieldState } = useController({
    name: 'graduationYear',
    control,
  });

  const touchAllFields = (fields: Record<keyof FormData, string | number>) => {
    Object.keys(fields).forEach((key) => {
      setValue(key as keyof FormData, getValues()[key as keyof FormData], {
        shouldTouch: true,
        shouldValidate: true,
      });
    });
  };

  const onSubmit = async (data: FormData) => {
    const isValid = await trigger();
    if (isValid) {
      updateUserProfile({
        email: data.email,
        phoneNumber: data.phone,
        parentData: {
          fullName: data.parentName,
          phoneNumber: data.parentPhone,
          email: data.parentMail,
        },
        preferredLanguage: data.language,
        timezone: data.timezone,
        city: data.city,
        country: data.country,
        graduationYear: data.graduationYear,
      });
    }
  };

  const submitHandler = (e: React.MouseEvent<HTMLButtonElement>) => {
    const formData = getValues();

    touchAllFields(formData);

    handleSubmit(onSubmit)(e);
  };

  useEffect(() => {
    const formData = getValues();

    touchAllFields(formData);
  }, []);

  return (
    <SessionModalFormWrapper
      title="Edit Contact Info"
      isLoading={false}
      onCancel={onCloseEditMode}
      submitHandler={submitHandler}
    >
      <div className="flex justify-between gap-6">
        <Input
          isRequired
          type="text"
          id="mail"
          label="Mail"
          isTouched={touchedFields.email}
          isValid={!errors.email}
          errorText={errors.email?.message}
          {...register('email', { required: true })}
        />
        <Input
          type="text"
          id="phone"
          label="Phone"
          isRequired
          isTouched={touchedFields.phone}
          isValid={!errors.phone}
          errorText={errors.phone?.message}
          {...register('phone', { required: true })}
        />
      </div>
      <div className="flex justify-between gap-6">
        <Input
          type="text"
          id="parentMail"
          label="Parent Mail"
          isRequired
          isTouched={touchedFields.parentMail}
          isValid={!errors.parentMail}
          errorText={errors.parentMail?.message}
          {...register('parentMail', { required: true })}
        />
        <Input
          type="text"
          id="parentPhone"
          label="Parent Phone"
          isRequired
          isTouched={touchedFields.parentPhone}
          isValid={!errors.parentPhone}
          errorText={errors.parentPhone?.message}
          {...register('parentPhone', { required: true })}
        />
      </div>
      <div className="flex justify-between gap-6">
        <Input
          type="text"
          id="parentName"
          label="Parent Name"
          isRequired
          isTouched={touchedFields.parentName}
          isValid={!errors.parentName}
          errorText={errors.parentName?.message}
          {...register('parentName', { required: true })}
        />
        <Select
          value={languageField.value}
          onSelect={(value) => languageField.onChange(value)}
          onClear={() => languageField.onChange(undefined)}
          onBlur={languageField.onBlur}
          options={supportedLanguages.map((language) => ({
            label: language,
            value: language,
          }))}
          size="large"
          label="Language"
          allowClear={false}
          isRequired
          isValid={!languageFieldState.error}
          isTouched={languageFieldState.isTouched}
          errorText={languageFieldState.error?.message}
        />
      </div>
      <div className="flex justify-between gap-6">
        <Select
          value={countryField.value}
          onSelect={(value) => countryField.onChange(value)}
          onClear={() => countryField.onChange(undefined)}
          onBlur={countryField.onBlur}
          options={countryList.map((country) => ({
            label: country,
            value: country,
          }))}
          isRequired
          allowClear={false}
          size="large"
          label="Country"
          isValid={!countryFieldState.error}
          isTouched={countryFieldState.isTouched}
          errorText={countryFieldState.error?.message}
        />
        <Input
          type="text"
          id="city"
          label="City"
          isRequired
          isTouched={touchedFields.city}
          isValid={!errors.city}
          errorText={errors.city?.message}
          {...register('city', { required: true })}
        />
      </div>
      <div className="flex justify-between gap-6">
        <Select
          value={timezoneField.value}
          onSelect={(value) => timezoneField.onChange(value)}
          onClear={() => timezoneField.onChange(undefined)}
          onBlur={timezoneField.onBlur}
          options={timezones.map((timezone) => ({
            label: timezone,
            value: timezone,
          }))}
          size="large"
          label="Timezone"
          isRequired
          allowClear={false}
          isValid={!timezoneFieldState.error}
          isTouched={timezoneFieldState.isTouched}
          errorText={timezoneFieldState.error?.message}
        />
        <AgoraDatePicker
          value={moment().year(graduationYearField.value).startOf('year')}
          onChange={(value) => graduationYearField.onChange(value?.year())}
          onBlur={graduationYearField.onBlur}
          picker="year"
          size="large"
          label="Graduation Year"
          isRequired
          allowClear={false}
          isValid={!graduationYearFieldState.error}
          isTouched={graduationYearFieldState.isTouched}
          errorText={graduationYearFieldState.error?.message}
        />
      </div>
    </SessionModalFormWrapper>
  );
};

export default StudentDetailsForm;
