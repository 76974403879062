import { LoadingSpinner } from '@components/LoadingSpinner/LoadingSpinner';
import BannerStack from '@components/V3/BannerStack';
import { banners } from '@components/V3/BannerStack/banners';
import UserHomeCard from '@components/V3/UserHomeCard';
import PageTitle from '@components/V3/Utils/PageTitle';
import YourCourses from '@components/V3/YourCourses';
import YourCoursesStudent from '@components/V3/YourCoursesStudent';
import { ProfileBanner } from '@modules/Account/Profile';
import MonthlyNPSModal from '@modules/Base/Home/PendingNPS/MonthlyNPSModal';
import FeedbackModal from '@modules/Student/components/FeedbackModal/FeedbackModal';
import { MENTOR_ROLE } from '@shared/constants';
import {
  useAuthState,
  useCheckPendingNPS,
  useStudentEnrollments,
  useStudentDetails,
} from '@shared/react';
import style from '@styles/components/reusables/page.module.scss';
import { Typography } from 'antd';
import React, { useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { COLORS } from '../../../../v3/global';
import OngoingMeetingsSection from '../Common/OngoingMeetingsSection';
import MeetingsNeedingFeedbackStudent from './MeetingsNeedingFeedbackStudent';
import NextMeetingsStudent from './NextMeetingsStudent';

const StudentHome: React.FC = () => {
  const { data, refetch } = useCheckPendingNPS();
  const firstUpgradeNPS = Math.random() < 0.5;

  const [showNpsModal, setShowNpsModal] = useState(false);
  const { userId, fullName, roles, avatar, isAdmin } = useAuthState();

  const { data: enrollments, isLoading, isError } = useStudentEnrollments();
  const { data: userDetails } = useStudentDetails(userId);

  const impersonateId = localStorage.getItem('impersonate-user-id');
  const userRole = localStorage.getItem('userRole');

  const location = useLocation();
  const history = useHistory();

  const params = new URLSearchParams(location.search);
  const finishedMeetingId = params.get('finishedMeetingId');

  let isMentor = undefined;
  if (impersonateId) {
    if (userRole === 'student') {
      isMentor = false;
    } else if (userRole === 'mentor') {
      isMentor = true;
    } else {
      isMentor = roles.includes(MENTOR_ROLE);
    }
  } else {
    isMentor = roles.includes(MENTOR_ROLE);
  }

  if (isLoading || enrollments === undefined) {
    return <LoadingSpinner />;
  }
  if (isError) {
    return <Typography.Paragraph>An error has occurred!</Typography.Paragraph>;
  }

  const handleSubmit = () => {
    setShowNpsModal(true);
  };

  const hideFeedbackModalHandler = () => {
    const params = new URLSearchParams(location.search);

    params.delete('finishedMeetingId');

    history.replace({
      pathname: location.pathname,
      search: params.toString(),
    });
  };

  return (
    <div className={style.mentorDashboardContainer}>
      {/*LEFT CONTAINER*/}
      {data && data?.type === 'monthly' && (
        <MonthlyNPSModal
          visible={true}
          firstUpgradeNPS={firstUpgradeNPS}
          onClose={() => {
            setShowNpsModal(false);
            refetch();
          }}
        />
      )}

      {showNpsModal && (
        <MonthlyNPSModal
          visible={showNpsModal}
          firstUpgradeNPS={firstUpgradeNPS}
          onClose={() => {
            setShowNpsModal(false);
            refetch();
          }}
        />
      )}

      <div
        style={{
          backgroundColor: COLORS.SECTION_BACKGROUND,
          minHeight: '100vh',
        }}
        className={`${style.page} ${style.mentorDashboardLeftContainer}`}
      >
        <div className={`${style.mentorDashboardLeftContainerContent}`}>
          <div
            style={{ marginBottom: '32px' }}
            className={`${style.mentorDashboardSectionMargin} ${style.mentorWelcomeMessage}`}
          >
            <PageTitle
              title={`Welcome back, ${fullName}`}
              description="Let's learn something new today!"
            />
          </div>

          <ProfileBanner hasSubBanner className="mb-6" />

          {!isMentor && !isAdmin && data && data?.type === 'monthly' && (
            <div className={style.feedbackBannerContainerDesktop}>
              <BannerStack
                handleSubmitFeedback={() => {
                  handleSubmit();
                }}
                banners={[banners[1]]}
              />
            </div>
          )}

          {!isMentor && !isAdmin && (
            <div>
              <div style={{ marginBottom: '24px' }}>
                <YourCoursesStudent enrollments={enrollments} />
              </div>

              <div className={style.mentorDashboardSectionMargin}>
                <YourCourses userRole={'student'} />
              </div>
            </div>
          )}
        </div>
      </div>

      {/*RIGHT CONTAINER*/}
      <div
        style={{
          backgroundColor: COLORS.SECTION_BACKGROUND,
        }}
        className={`${style.page} ${style.mentorDashboardRightContainer}`}
      >
        {!isMentor && !isAdmin && data && data?.type === 'monthly' && (
          <div
            style={{ marginBottom: '32px', marginTop: '16px' }}
            className={style.feedbackBannerContainerMobile}
          >
            <BannerStack
              handleSubmitFeedback={() => {
                handleSubmit();
              }}
              banners={[banners[1]]}
            />
          </div>
        )}

        <div
          className={`${style.mentorDashboardSectionMargin} ${style.mentorDashboardBalanceUserContainer}`}
        >
          <div className="ml-auto">
            <UserHomeCard
              name={fullName}
              description={`Graduation year: ${userDetails?.graduationYear}`}
              avatar={avatar}
            />
          </div>
        </div>

        {!isMentor && !isAdmin && (
          <div>
            {/* ONGOING MEETINGS */}
            <OngoingMeetingsSection isMentor={false} />

            {/* MEETINGS NEEDING FEEDBACK */}
            <MeetingsNeedingFeedbackStudent />

            {/* SCHEDULED MEETINGS */}
            <NextMeetingsStudent />
          </div>
        )}
      </div>

      {finishedMeetingId && (
        <FeedbackModal
          openedOnMeetingEnd
          isOpen={true}
          meetingId={finishedMeetingId}
          onClose={hideFeedbackModalHandler}
        />
      )}
    </div>
  );
};

export default StudentHome;
