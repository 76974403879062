import { useEffect } from 'react';

import { useLocation } from 'react-router-dom';
import { useAnalytics } from '../contexts/AnalyticsContext';
import { usePrevious } from './usePrevious';

const useTrackPageView = () => {
  const { trackPageView } = useAnalytics();
  const location = useLocation();
  const prevLocation = usePrevious(location);

  useEffect(() => {
    if (prevLocation?.pathname === location.pathname) return;

    trackPageView(`${location.pathname}${location.search}`);
  }, [location]);
};

export default useTrackPageView;
