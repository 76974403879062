import { ReactComponent as SchoolIcon } from '@assets/icons/profile-icons/school-icon.svg';
import AgoraDatePicker from '@components/V3/Utils/InputsV3/AgoraDatePicker';
import Input from '@components/V4/Inputs/Input';
import { zodResolver } from '@hookform/resolvers/zod';
import { EducationalBackground } from '@shared/common';
import { useSaveUserProfile } from '@shared/react';
import { useAnalytics } from 'apps/agora/src/contexts/AnalyticsContext';
import useToast from 'apps/agora/src/hooks/useToast';
import { ANALYTICS_EVENT_NAMES } from 'apps/agora/src/utils/constants';
import moment from 'moment';
import { useContext, useEffect } from 'react';
import { useController, useForm } from 'react-hook-form';
import { useQueryClient } from 'react-query';
import { z } from 'zod';
import { MainProfileContext } from '../../../AccountProfile';
import { ExamFormProps } from '../../../types';
import StudentFormWrapper from '../../ProfileFormWrapper';

interface ResumeTabSchoolFormProps extends ExamFormProps {
  schoolData?: EducationalBackground;
}

const formDataSchema = z.object({
  name: z.string().min(1, { message: 'Please enter a valid school name.' }),
  gpa: z.number().min(0.5, { message: 'Please enter a valid GPA.' }),
  startDate: z.string().min(1, { message: 'Please enter a valid start date.' }),
  endDate: z.string().min(1, { message: 'Please enter a valid end date.' }),
});

type FormData = z.infer<typeof formDataSchema>;

const ResumeTabSchoolForm = (props: ResumeTabSchoolFormProps) => {
  const { isModeAddNew, userId, schoolData, setIsEditMode, setIsAddNewItem, onCancel } = props;

  const { isExternalViewer } = useContext(MainProfileContext);

  const [showToast] = useToast();
  const query = useQueryClient();
  const { trackEvent } = useAnalytics();

  const {
    register,
    handleSubmit,
    trigger,
    setValue,
    control,
    getValues,
    formState: { errors, touchedFields },
    reset,
  } = useForm<FormData>({
    resolver: zodResolver(formDataSchema),
    reValidateMode: 'onBlur',
    mode: 'onBlur',
    defaultValues: {
      name: schoolData?.educationalInstitution.name,
      gpa: schoolData?.gpa ?? 0,
      startDate: schoolData?.startDate,
      endDate: schoolData?.endDate,
    },
  });

  const { field: startDateField, fieldState: startDateFieldState } = useController({
    name: 'startDate',
    control,
  });

  const { field: endDateField, fieldState: endDateFieldState } = useController({
    name: 'endDate',
    control,
  });

  const editButtonHandler = () => {
    setIsEditMode?.(false);
    setIsAddNewItem?.(false);
    reset();
  };

  const { mutate: updateUserProfile, isLoading } = useSaveUserProfile(userId, {
    onSuccess: async () => {
      trackEvent(
        isExternalViewer
          ? ANALYTICS_EVENT_NAMES.STUDENT_PROFILE_CHANGE_BY_MENTOR
          : ANALYTICS_EVENT_NAMES.PROFILE_CHANGE
      );

      showToast({
        variant: 'success',
        messageTitle: 'Success',
        messageBody: 'Successfully updated your profile.',
      });
      await query.invalidateQueries('/users/:id/profile');
      editButtonHandler();
    },
    onError: () => {
      showToast({
        variant: 'error',
        messageTitle: 'Error',
        messageBody: 'Profile could not be saved.',
      });
    },
  });

  const onSubmit = async (data: FormData) => {
    const isValid = await trigger();
    if (isValid) {
      const universityData: EducationalBackground = {
        educationalInstitution: {
          name: data.name,
          type: 'School',
        },
        gpa: data.gpa,
        startDate: data.startDate,
        endDate: data.endDate,
        _id: schoolData?._id,
      };

      updateUserProfile({
        educationalBackground: [universityData],
      });
    }
  };

  const touchAllFields = (fields: Record<keyof FormData, string | number>) => {
    Object.keys(fields).forEach((key) => {
      setValue(key as keyof FormData, getValues()[key as keyof FormData], {
        shouldTouch: true,
        shouldValidate: true,
      });
    });
  };

  const submitHandler = (e: React.MouseEvent<HTMLButtonElement>) => {
    const formData = getValues();

    touchAllFields(formData);

    handleSubmit(onSubmit)(e);
  };

  useEffect(() => {
    if (!isModeAddNew) {
      const formData = getValues();

      touchAllFields(formData);
    }
  }, []);

  return (
    <StudentFormWrapper
      title="School"
      isModeAddNew={isModeAddNew}
      onCancel={onCancel}
      icon={<SchoolIcon className="min-w-5" />}
      isLoading={isLoading}
      editButtonHandler={editButtonHandler}
      submitHandler={submitHandler}
    >
      <Input
        type="text"
        id={'name'}
        placeholder={''}
        label={'School Name'}
        isRequired
        isTouched={touchedFields.name}
        isValid={!errors.name}
        errorText={errors.name?.message}
        autofocus
        {...register('name')}
      />
      <Input
        type="number"
        id={'gpa'}
        placeholder={''}
        label={'GPA'}
        isRequired
        isTouched={touchedFields.gpa}
        isValid={!errors.gpa}
        errorText={errors.gpa?.message}
        {...register('gpa', { valueAsNumber: true })}
      />
      <div className="gap-6 laptop:flex">
        <AgoraDatePicker
          value={startDateField.value ? moment(startDateField.value) : undefined}
          onChange={(value) => startDateField.onChange(moment(value).format('YYYY-MM-DD'))}
          onBlur={startDateField.onBlur}
          allowClear={false}
          isRequired
          label="Start Date"
          isValid={!startDateFieldState.error}
          isTouched={startDateFieldState.isTouched}
          errorText={startDateFieldState.error?.message}
        />

        <AgoraDatePicker
          value={endDateField.value ? moment(endDateField.value) : undefined}
          onChange={(value) => endDateField.onChange(moment(value).format('YYYY-MM-DD'))}
          onBlur={endDateField.onBlur}
          allowClear={false}
          isRequired
          label="End Date"
          isValid={!endDateFieldState.error}
          isTouched={endDateFieldState.isTouched}
          errorText={endDateFieldState.error?.message}
        />
      </div>
    </StudentFormWrapper>
  );
};

export default ResumeTabSchoolForm;
