import { MENTOR_ROLE } from '@shared/constants';
import { useAuthState, useGetUserProfile } from '@shared/react';
import { createContext } from 'react';
import ProfileAside from './ProfileAside/ProfileAside';
import ProfileMain from './ProfileMain/ProfileMain';

interface AccountProfileProps {
  userId?: string;
  isMentorProfile?: boolean;
}

export const MainProfileContext = createContext({
  isMentorViewer: false,
  isExternalViewer: false,
});

const AccountProfile = (props: AccountProfileProps) => {
  const { userId, isMentorProfile = false } = props;

  const user = useAuthState();
  const { data: userProfile } = useGetUserProfile(userId ?? user.userId);

  const isExternalViewer = user.userId !== userProfile?._id;
  const isMentorViewer = user.roles.includes(MENTOR_ROLE);

  return (
    <section className="flex flex-col p-4 gap-4 max-w-[1654px] m-auto tablet:flex-row tablet:items-start">
      <ProfileAside
        hasEditPermissions={!isExternalViewer}
        userProfile={userProfile}
        isMentorProfile={isMentorProfile}
      />
      <MainProfileContext.Provider value={{ isMentorViewer, isExternalViewer }}>
        <ProfileMain userProfile={userProfile} isMentorProfile={isMentorProfile} />
      </MainProfileContext.Provider>
    </section>
  );
};

export default AccountProfile;
