import { LoadingSpinner } from '@components/LoadingSpinner/LoadingSpinner';
import RangePicker from '@components/V4/RangePicker/RangePicker';
import Select, { SelectOption } from '@components/V4/Select/Select';
import Table, { SortOrder } from '@components/V4/Table/Table';
import { useDebounce, useQueryParamsState } from '@hooks';
import { StudentTab } from '@modules/Admin/components/SessionModal/SessionDetailsPage/SessionTabsSection/SessionTabsSection';
import SessionModal, {
  SessionModalPage,
} from '@modules/Admin/components/SessionModal/SessionModal';
import { SESSION_STATUSES } from '@shared/constants';
import {
  useGetAllCoursesLite,
  useGetAllMentors,
  useGetAllStudents,
  useSearchAllSessions,
} from '@shared/react';
import { Checkbox } from 'antd';
import {
  convertSecondsToTime,
  getEndDateValue,
  getStartDateValue,
  getValidMoment,
} from 'apps/agora/src/utils/helpers';
import moment, { Moment } from 'moment';
import { useState } from 'react';
import { useLocation } from 'react-router-dom';

const useQuery = () => new URLSearchParams(useLocation().search);

const AllSessions = () => {
  const [studentSearch, setStudentSearch] = useState('');
  const [mentorSearch, setMentorSearch] = useState('');

  const debouncedStudentSearch = useDebounce(studentSearch, 500);
  const debouncedMentorSearch = useDebounce(mentorSearch, 500);

  const [mentorId, setMentorId] = useState<string>();
  const [studentId, setStudentId] = useState<string>();
  const [sessionId, setSessionId] = useState<string>();
  const [sessionModalTab, setSessionModalTab] = useState<StudentTab | undefined>();
  const [showSessionModal, setShowSessionModal] = useState(false);
  const [enrollmentId, setEnrollmentId] = useState<string>();
  const [modalPage, setModalPage] = useState<SessionModalPage>('student');

  //TODO - replace this with useQueryParams when upgrading to react-router-dom v6
  const query = useQuery();
  const student = query.get('student') || null;
  const mentor = query.get('mentor') || null;
  const status = query.get('status') || null;
  const course = query.get('course') || null;
  const limit = query.get('limit') || '10';
  const page = query.get('page') || '1';
  const rangeStart = getValidMoment(query.get('rangeStart'));
  const rangeEnd = getValidMoment(query.get('rangeEnd'));
  const sortBy = query.get('sortBy') || null;
  const sortOrder = (query.get('sortOrder') || null) as SortOrder;
  const hideNoMeetingSessions = (query.get('hideNoMeetingSessions') || 'checked') as
    | 'checked'
    | 'unchecked'
    | 'indeterminate';

  const [searchParams, setSearchParams] = useQueryParamsState<any>({
    page: page,
    limit: limit,
    student: student,
    mentor: mentor,
    rangeStart: rangeStart,
    rangeEnd: rangeEnd,
    status: status,
    course: course,
    sortBy: sortBy,
    sortOrder: sortOrder,
    hideNoMeetingSessions: hideNoMeetingSessions,
  });

  const { data: allSessions, isLoading: isLoadingAllMeetings } = useSearchAllSessions({
    limit: searchParams.limit,
    page: searchParams.page,
    status: searchParams.status,
    course: searchParams.course,
    student: searchParams.student,
    mentor: searchParams.mentor,
    rangeStart: searchParams.rangeStart,
    rangeEnd: searchParams.rangeEnd,
    sortBy: searchParams.sortBy,
    sortOrder: searchParams.sortOrder,
    hideNoMeetingSessions: searchParams.hideNoMeetingSessions,
  });
  const { data: courses } = useGetAllCoursesLite();

  const { data: students, isLoading: isStudentsLoading } = useGetAllStudents({
    search: debouncedStudentSearch,
    limit: 20,
  });

  const { data: mentors, isLoading: isMentorsLoading } = useGetAllMentors({
    search: debouncedMentorSearch,
    limit: 20,
  });

  const rangePickerChangeHandler = (dates: [Moment | null, Moment | null]) => {
    const start = dates?.[0] ? getStartDateValue(dates?.[0]) : null;
    const end = dates?.[1] ? getEndDateValue(dates?.[1]) : null;

    setSearchParams({
      rangeStart: start ? start.toISOString() : null,
      rangeEnd: end ? end.toISOString() : null,
      page: 1,
    });
  };

  const currentPageChangeHandler = (page: number) => {
    setSearchParams({
      page,
    });
  };

  const pageSizeChangeHandler = (size: number) => {
    setSearchParams({
      limit: size,
    });
  };

  const getStatusOptions = () => {
    const statusOptions = Object.keys(SESSION_STATUSES)?.map((key) => ({
      label: SESSION_STATUSES[key],
      value: key,
    }));

    return [{ label: 'ALL', value: null }, ...statusOptions];
  };

  const getCourseOptions = () => {
    const courseOptions =
      courses?.map((course) => ({
        label: course.title,
        value: course._id,
      })) || [];

    return [{ label: 'ALL', value: null }, ...courseOptions];
  };

  const getMentorOptions = () => {
    const mentorOptions: SelectOption[] =
      mentors?.map((mentor) => ({
        label: mentor.fullName,
        value: mentor._id,
      })) || [];

    if (!debouncedMentorSearch) {
      mentorOptions.unshift({ label: 'ALL', value: null });
    }

    return mentorOptions;
  };

  const getStudentOptions = () => {
    const studentOptions: SelectOption[] =
      students?.map((student) => ({
        label: student.fullName,
        value: student._id,
      })) || [];

    if (!debouncedStudentSearch) {
      studentOptions.unshift({ label: 'ALL', value: null });
    }

    return studentOptions;
  };

  const getRangePickerValue = (): [Moment, Moment] | null => {
    if (!searchParams.rangeStart || !searchParams.rangeEnd) return null;

    return [moment(searchParams.rangeStart), moment(searchParams.rangeEnd)];
  };

  const sortHandler = (field: string, order: SortOrder) => {
    setSearchParams({
      sortBy: order ? field : null,
      sortOrder: order || null,
      page: 1,
    });
  };

  const getFeedbackCellClassName = (feedbackDate: Date, completionDate: Date) => {
    if (completionDate) {
      const diffInHours = moment(completionDate).diff(moment(feedbackDate), 'hours');

      if (diffInHours > 48) {
        return 'text-customRed';
      }
    }

    return 'text-customGrey';
  };

  const mentorModalOpenHandler = (mentorId: string) => {
    setModalPage('mentor');
    setMentorId(mentorId);
    setShowSessionModal(true);
  };

  const enrollmentModalOpenHandler = (enrollmentId: string) => {
    setModalPage('enrollment');
    setEnrollmentId(enrollmentId);
    setShowSessionModal(true);
  };

  const sessionModalOpenHandler = (sessionId: string, tab?: StudentTab) => {
    setModalPage('session');
    setSessionId(sessionId);
    setShowSessionModal(true);
    setSessionModalTab(tab ?? 'meetings');
  };

  const checkboxChangeHandler = () => {
    switch (hideNoMeetingSessions) {
      case 'checked':
        setSearchParams({ hideNoMeetingSessions: 'unchecked', page: 1 });
        return;
      case 'unchecked':
        setSearchParams({ hideNoMeetingSessions: 'indeterminate', page: 1 });
        return;
      case 'indeterminate':
        setSearchParams({ hideNoMeetingSessions: 'checked', page: 1 });
        return;
    }
  };

  const openStudentDetailsModalHandler = (studentId: string) => {
    setStudentId(studentId);
    setShowSessionModal(true);
  };

  const closeSessionModalHandler = () => {
    setStudentId(undefined);
    setEnrollmentId(undefined);
    setShowSessionModal(false);
    setModalPage(undefined);
  };

  return (
    <div className="flex flex-col">
      <div className="flex flex-col gap-6 bg-surfaceObject px-18 pt-8 pb-6">
        <div className="flex justify-between items-center">
          <h3 className="text-xl font-bold">All Sessions</h3>
        </div>
        <div className="flex gap-6">
          <Select
            className="w-1/6 max-w-64"
            label="Course"
            value={course}
            onSelect={(value) => setSearchParams({ course: value, page: 1 })}
            options={getCourseOptions()}
            placeholder="Search Course"
            variant="secondary"
          ></Select>
          <Select
            className="w-1/6 max-w-64"
            label="Students"
            value={student}
            filterOption={() => true}
            searchValue={studentSearch}
            onSearch={setStudentSearch}
            onBlur={() => setStudentSearch('')}
            onSelect={(value) => setSearchParams({ student: value, page: 1 })}
            options={getStudentOptions()}
            notFoundContent={
              isStudentsLoading ? <LoadingSpinner className="mx-auto" size="normal" /> : null
            }
            placeholder="Search Students"
            variant="secondary"
          />
          <Select
            className="w-1/6 max-w-64"
            label="Mentor"
            value={mentor}
            filterOption={() => true}
            searchValue={mentorSearch}
            onSearch={setMentorSearch}
            onSelect={(value) => setSearchParams({ mentor: value, page: 1 })}
            onBlur={() => setMentorSearch('')}
            options={getMentorOptions()}
            notFoundContent={
              isMentorsLoading ? <LoadingSpinner className="mx-auto" size="normal" /> : null
            }
            placeholder="Search Mentor"
            variant="secondary"
          />
          <Select
            className="w-1/6 max-w-64"
            label="Status"
            value={status}
            options={getStatusOptions()}
            onSelect={(value) => setSearchParams({ status: value, page: 1 })}
            placeholder="Search Status"
            variant="secondary"
          />
          <RangePicker
            variant="secondary"
            disabled={hideNoMeetingSessions === 'unchecked'}
            label="Date Range"
            className="w-64"
            value={getRangePickerValue()}
            onChange={rangePickerChangeHandler}
          />
        </div>
        <div className="flex items-center gap-2">
          <Checkbox
            id="hideNoMeetingSessions"
            indeterminate={hideNoMeetingSessions === 'indeterminate'}
            checked={hideNoMeetingSessions === 'checked'}
            onChange={checkboxChangeHandler}
          />
          <label
            htmlFor="hideNoMeetingSessions"
            className="text-xsm font-semibold text-white cursor-pointer"
          >
            Hide no meeting sessions
          </label>
        </div>
      </div>
      <div className="mb-20 mt-8">
        {isLoadingAllMeetings ? (
          <div className="h-24 flex items-center justify-center text-customGrey">Loading...</div>
        ) : (
          <Table
            className="px-18"
            pagination={{
              pageSize: limit !== null ? parseInt(limit) : 10,
              total: allSessions?.total,
              currentPage: page !== null ? parseInt(page) : 1,
              onCurrentPageChange: currentPageChangeHandler,
              onPageSizeChange: pageSizeChangeHandler,
            }}
          >
            <Table.Header>
              <Table.Row isHeader>
                <Table.HeaderCell
                  onSort={(order) => sortHandler('name', order)}
                  sortOrder={sortBy === 'name' ? sortOrder : undefined}
                >
                  Session Name
                </Table.HeaderCell>
                <Table.HeaderCell>Student</Table.HeaderCell>
                <Table.HeaderCell>Mentor</Table.HeaderCell>
                <Table.HeaderCell
                  onSort={(order) => sortHandler('startDate', order)}
                  sortOrder={sortBy === 'startDate' ? sortOrder : undefined}
                >
                  Date
                </Table.HeaderCell>
                <Table.HeaderCell
                  onSort={(order) => sortHandler('feedback', order)}
                  sortOrder={sortBy === 'feedback' ? sortOrder : undefined}
                >
                  Feedback
                </Table.HeaderCell>
                <Table.HeaderCell
                  onSort={(order) => sortHandler('duration', order)}
                  sortOrder={sortBy === 'duration' ? sortOrder : undefined}
                >
                  Billable Time
                </Table.HeaderCell>
                <Table.HeaderCell
                  onSort={(order) => sortHandler('status', order)}
                  sortOrder={sortBy === 'status' ? sortOrder : undefined}
                >
                  Status
                </Table.HeaderCell>
                <Table.HeaderCell>Go To</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {allSessions?.sessions?.map((session) => (
                <Table.Row key={session._id}>
                  <Table.Cell onClick={() => sessionModalOpenHandler(session._id)}>
                    {session.name ?? 'n/a'}
                  </Table.Cell>
                  <Table.Cell
                    onClick={() => openStudentDetailsModalHandler(session?.students[0]?._id)}
                  >
                    {session?.students?.[0]?.fullName ?? 'n/a'}
                  </Table.Cell>
                  <Table.Cell
                    onClick={session.mentor && (() => mentorModalOpenHandler(session.mentor._id))}
                  >
                    {session.mentor?.fullName ?? 'n/a'}
                  </Table.Cell>
                  <Table.Cell>
                    {session.startDate
                      ? moment(session.startDate).format('DD MMM YYYY, HH:mm:SS')
                      : 'n/a'}
                  </Table.Cell>
                  <Table.Cell
                    onClick={() => sessionModalOpenHandler(session._id, 'feedback')}
                    className={getFeedbackCellClassName(
                      session.feedbackDate,
                      session.completionDate
                    )}
                  >
                    {session.feedbackDate
                      ? moment(session.feedbackDate).format('DD MMM YYYY, HH:mm')
                      : 'n/a'}
                  </Table.Cell>
                  <Table.Cell>
                    {session?.billableTime === 0
                      ? '0m'
                      : convertSecondsToTime(session?.billableTime)}
                  </Table.Cell>
                  <Table.Cell>{SESSION_STATUSES[session.status]}</Table.Cell>
                  <Table.Cell onClick={() => enrollmentModalOpenHandler(session.enrollmentId)}>
                    Enrollment
                  </Table.Cell>
                </Table.Row>
              ))}
            </Table.Body>
          </Table>
        )}
      </div>
      {showSessionModal && (
        <SessionModal
          mentorId={mentorId}
          studentId={studentId}
          enrollmentId={enrollmentId}
          sessionId={sessionId}
          page={modalPage}
          isOpen={true}
          onClose={closeSessionModalHandler}
          tab={sessionModalTab}
        />
      )}
    </div>
  );
};

export default AllSessions;
