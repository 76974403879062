import Button from '@components/V4/Button';
import TextArea from '@components/V4/Inputs/Textarea';
import Modal from '@components/V4/Modal/Modal';
import Pill from '@components/V4/Pill';
import StarRating from '@components/V4/StarRating/StarRating';
import { enrollmentsApis } from '@shared/apis';
import { useRegisterStudentFeedback } from '@shared/react';
import { useAnalytics } from 'apps/agora/src/contexts/AnalyticsContext';
import useToast from 'apps/agora/src/hooks/useToast';
import { ANALYTICS_EVENT_NAMES } from 'apps/agora/src/utils/constants';
import { BasicProps } from 'apps/agora/src/utils/types';
import { useEffect, useState } from 'react';
import { useQueryClient } from 'react-query';

interface FeedbackModalProps extends BasicProps {
  meetingId: string;
  isOpen: boolean;
  openedOnMeetingEnd?: boolean;
  onClose: () => void;
}

const feedbackOptions = {
  good: [
    'Mentor’s guidance was impactful',
    'Course materials helped me learn effectively',
    'Upgrade provided excellent support',
  ],
  bad: [
    'Mentor’s guidance could be more helpful',
    'Course materials need improvement',
    'Upgrade support needs to be more responsive',
  ],
};

const POSITIVE_RATING_THRESHOLD = 4;

const FeedbackModal = (props: FeedbackModalProps) => {
  const { isOpen, meetingId, openedOnMeetingEnd = false, onClose } = props;

  const [rating, setRating] = useState<number>(0);
  const [selectedFeedbackSuggestions, setSelectedFeedbackSuggestions] = useState<
    Record<string, boolean>
  >({});
  const [textFeedback, setTextFeedback] = useState('');

  const reactQuery = useQueryClient();

  const { trackEvent } = useAnalytics();

  const [showToast] = useToast();

  useEffect(() => {
    if (openedOnMeetingEnd) {
      trackEvent(ANALYTICS_EVENT_NAMES.FEEDBACK_MODAL_ON_END_MEETING_SHOW);
    }
  }, [openedOnMeetingEnd]);

  const { mutate: registerStudentFeedback, isLoading } = useRegisterStudentFeedback(meetingId, {
    onSuccess: () => {
      onClose();
      reactQuery.invalidateQueries(enrollmentsApis.getSessionDetails.endpoint);

      if (openedOnMeetingEnd) {
        trackEvent(ANALYTICS_EVENT_NAMES.MEETING_GIVE_FEEDBACK);
        return;
      }
      trackEvent(ANALYTICS_EVENT_NAMES.FEEDBACK_ON_END_MEETING);
    },
    onError: () =>
      showToast({
        variant: 'error',
        messageBody: "There was an error and your feedback couldn't be submitted",
      }),
  });

  const ratingChangeHandler = (value: number) => {
    const wasPositive = rating > POSITIVE_RATING_THRESHOLD;
    const isPositive = value > POSITIVE_RATING_THRESHOLD;

    if (wasPositive !== isPositive) {
      setSelectedFeedbackSuggestions({});
    }

    setRating(value);
  };

  const submitHandler = () => {
    registerStudentFeedback({
      rating: rating,
      message:
        Object.keys(selectedFeedbackSuggestions)
          .filter((key) => !!selectedFeedbackSuggestions[key])
          .join(', ') +
        ' \n' +
        textFeedback,
    });
  };

  const ratingQuality = rating > 4 ? 'good' : 'bad';

  const closeClickHandler = () => {
    onClose();

    if (openedOnMeetingEnd) {
      trackEvent(ANALYTICS_EVENT_NAMES.CLOSE_FEEDBACK_MODAL_ON_MEETING_END);
      return;
    }

    trackEvent(ANALYTICS_EVENT_NAMES.CLOSE_FEEDBACK_MODAL);
  };

  return (
    <Modal className="font-raleway" size="large" isOpen={isOpen} onClose={closeClickHandler}>
      <Modal.Header title="Share your feedback in seconds.">
        <span className="w-full text-center text-sm text-customGrey -mt-2">
          This will help both your mentor and us improve
        </span>
      </Modal.Header>

      <Modal.Body>
        <StarRating
          className="mt-12 w-full items-center"
          value={rating}
          onChange={ratingChangeHandler}
        />

        {!!rating && (
          <div className="flex flex-col gap-6 mt-12">
            <p>
              {ratingQuality === 'good'
                ? "We’re glad you're thriving in your course! What’s working best for you?"
                : 'Let’s improve your experience. What needs attention?'}
            </p>
            <div className="flex flex-wrap gap-4">
              {feedbackOptions[ratingQuality].map((feedback) => (
                <Pill
                  key={feedback}
                  size="default"
                  color="light"
                  text={feedback}
                  isSelected={selectedFeedbackSuggestions[feedback]}
                  onClick={() =>
                    setSelectedFeedbackSuggestions((prev) => ({
                      ...prev,
                      [feedback]: !prev[feedback],
                    }))
                  }
                />
              ))}
            </div>
            <TextArea
              value={textFeedback}
              onChange={(e) => setTextFeedback(e.target.value)}
              className="mb-6"
              name="other-feedback"
              id="other-feedback"
              label="Other:"
            />

            <Button
              size="large"
              className="w-40 self-end"
              buttonText="Submit Feedback"
              isLoading={isLoading}
              onClick={submitHandler}
            />
          </div>
        )}
      </Modal.Body>
    </Modal>
  );
};

export default FeedbackModal;
