import DriveIcon from '@assets/icons/Icon-awesome-google-drive.png';
import HomeworkIcon from '@assets/icons/Icon-awesome-home.png';
import { useModal } from '@hooks';

import { CreateHomeworkModal } from '@modules/Mentor/reusables';
import { MeetingWithHomework } from '@shared/common';
import { ENVIRONMENT } from '@shared/frontendEnv';
import { useUserDetails } from '@shared/react';
import { documentUrl } from '@shared/utils/string';
import btn from '@styles/components/reusables/buttons.module.scss';
import { useAnalytics } from 'apps/agora/src/contexts/AnalyticsContext';
import useToast from 'apps/agora/src/hooks/useToast';
import { ANALYTICS_EVENT_NAMES } from 'apps/agora/src/utils/constants';
import React from 'react';
import { Link } from 'react-router-dom';
import { COLORS } from '../../../../../v3/global';
import MeetingListItemButton from './MeetingListItemButton';

interface HomeworkButtonProps {
  meeting: MeetingWithHomework;
  refetch?: () => void;
}

const HomeworkButton: React.FC<HomeworkButtonProps> = (props: HomeworkButtonProps) => {
  const { meeting, refetch } = props;
  const [HomeworkModal, showHomeworkModal] = useModal(CreateHomeworkModal);
  const homework = meeting.homework;

  const { trackEvent } = useAnalytics();

  const { data: user } = useUserDetails(meeting?.students[0]?._id);

  const [showToast] = useToast();

  const googleDrive = user?.googleDrive[`${ENVIRONMENT}`]?.student?.id;

  const handleClick = () => {
    trackEvent(ANALYTICS_EVENT_NAMES.MEETING_ADD_HOMEWORK_BUTTON_CLICK);

    if (!googleDrive) {
      return showToast({
        variant: 'error',
        messageBody: 'Google drive error, please get in touch with an administrator',
      });
    }

    if (!homework) {
      showHomeworkModal({
        enrollmentId: meeting?.enrollment?._id,
        studentId: meeting.students[0]._id,
        meetingId: meeting._id,
        refetch,
      });
    }

    if (homework?.fileId) {
      window.open(documentUrl(homework.fileId), '_blank');
    }
  };

  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      {googleDrive && (
        <MeetingListItemButton>
          <Link
            className="flex items-center w-full h-full text-white py-2 px-4"
            to={{
              pathname: `https://drive.google.com/drive/folders/${googleDrive}`,
            }}
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={DriveIcon} alt="drive-icon" style={{ marginRight: '12px', width: '14px' }} />
            <span className="text-white">Open Drive</span>
          </Link>
        </MeetingListItemButton>
      )}

      <MeetingListItemButton>
        <div
          className="flex items-center w-full h-full text-inherit py-2 px-4"
          onClick={handleClick}
        >
          <img src={HomeworkIcon} alt="homework-icon" className="mr-3 w-3.5 h-3" />
          <span
            style={{
              color: COLORS.TEXT_PRIMARY,
              whiteSpace: 'nowrap',
            }}
            className={`${btn.actionButton} gradient`}
          >
            {homework?.fileId ? 'View Homework' : 'Add Homework'}
          </span>
        </div>
      </MeetingListItemButton>

      {HomeworkModal}
    </div>
  );
};

export default HomeworkButton;
