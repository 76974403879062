import { useAuth0 } from '@auth0/auth0-react';
import { LoadingSpinner } from '@components/LoadingSpinner/LoadingSpinner';
import { MobileProvider } from '@components/Navigation/MobileContext';
import Auth from '@modules/Auth';
import LoginErrorView from '@modules/Auth/LoginErrorScreen';
import ParentView from '@modules/Auth/ParentView';
import ReferralThankYouView from '@modules/Auth/ReferralThankYouView/index';
import ReferralView from '@modules/Auth/ReferralView/index';
import TermsAndConditionsView from '@modules/Auth/TermsAndConditionsView';
import Licenses from '@modules/Licenses';
import PaymentSuccess from '@modules/PaymentSuccess';
import useHotjar from '@services/useHotjar';
import { USE_AUTH0 } from '@shared/frontendEnv';
import { useAuthState } from '@shared/react';
import { ConfigProvider } from 'antd';
import enGB from 'antd/es/locale/en_GB';
import React, { useEffect, useState } from 'react';
import { Redirect, Route, Switch, useHistory } from 'react-router-dom';
import useTrackPageView from './hooks/useTrackPageView';
import { getIsPublicPath } from './utils/helpers';
import { AdminView, UserView } from './views';

const Router: React.FC = () => {
  const history = useHistory();

  const { isAdmin, isLoading, isLogoutInProgress } = useAuthState();

  const { error, isAuthenticated, loginWithRedirect } = useAuth0();
  const [authFlag] = useState<boolean>(USE_AUTH0 === '1');

  const impersonateId = localStorage.getItem('impersonate-user-id');

  const [loading, setLoading] = useState(true);
  const [hasAdminRoles, setHasAdminRoles] = useState<boolean | undefined>(undefined);

  useTrackPageView();
  useHotjar();

  useEffect(() => {
    if (isLogoutInProgress || isAuthenticated) return;

    if (!getIsPublicPath() && !isAuthenticated && !isLoading) {
      localStorage.setItem('desiredPath', window.location.pathname + window.location.search);

      loginWithRedirect();
    }
  }, [isAuthenticated, isLoading, isLogoutInProgress]);

  useEffect(() => {
    setHasAdminRoles(isAdmin);
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }, [isAdmin, isAuthenticated]);

  useEffect(() => {
    if (isLogoutInProgress) return;

    if (isAuthenticated && localStorage.getItem('desiredPath')) {
      const path = localStorage.getItem('desiredPath');
      localStorage.removeItem('desiredPath');

      history.push(path || '/');
    }
  }, [isAuthenticated, isLogoutInProgress]);

  if (loading) {
    return (
      <div className="flex items-center justify-center w-full h-full">
        <LoadingSpinner />
      </div>
    );
  } else {
    if (isAuthenticated && window.location.pathname.startsWith('/referral')) {
      return (
        <Switch>
          <Route path="/referral/:id" component={ReferralView} />
          <Route path="/referral" component={ReferralView} />
        </Switch>
      );
    }

    if (
      (isAuthenticated && window.location.pathname === '/thank-you') ||
      window.location.pathname === '/thank-you/'
    ) {
      return (
        <Switch>
          <Route path="/thank-you" component={ReferralThankYouView} />
        </Switch>
      );
    }

    if (isAuthenticated && window.location.pathname === '/terms-and-conditions') {
      return (
        <Switch>
          <Route path="/terms-and-conditions" component={TermsAndConditionsView} />
        </Switch>
      );
    }

    if (isAuthenticated && window.location.pathname.includes('/parent-view')) {
      return (
        <Switch>
          <Route path="/parent-view/:studentId" component={ParentView} />
        </Switch>
      );
    }

    if (!isAuthenticated || error) {
      if (
        (window.location.pathname === '/' ||
          window.location.pathname === '/auth' ||
          window.location.pathname === '/auth/') &&
        authFlag
      ) {
        if (error?.toString().includes('block')) {
          history.push('/login-error');
        } else {
          loginWithRedirect();
        }
      }

      return (
        <Switch>
          <Route path="/parent-view/:studentId" component={ParentView} />
          <Route path="/auth" component={Auth} />
          <Route path="/licenses" component={Licenses} />
          <Route path="/payment/success/:invoiceId" component={PaymentSuccess} />
          <Route path="/referral/:id" component={ReferralView} />
          <Route path="/referral" component={ReferralView} />
          <Route path="/thank-you" component={ReferralThankYouView} />
          <Route path="/login-error" component={LoginErrorView} />
          <Route path="/terms-and-conditions" component={TermsAndConditionsView} />
          <Redirect to="/" />
        </Switch>
      );
    }

    return (
      <MobileProvider>
        <ConfigProvider locale={enGB}>
          <div className="main_wrapper h-full">
            {!isLoading && hasAdminRoles && !impersonateId && isAuthenticated && <AdminView />}
            {!isLoading && (!hasAdminRoles || impersonateId) && isAuthenticated && <UserView />}
          </div>
        </ConfigProvider>
      </MobileProvider>
    );
  }
};

export default Router;
