import Input from '@components/V4/Inputs/Input';
import Select from '@components/V4/Select/Select';
import { zodResolver } from '@hookform/resolvers/zod';
import { MentorData, supportedCurrencies } from '@shared/common';
import { useSaveUserProfile } from '@shared/react';
import { useAnalytics } from 'apps/agora/src/contexts/AnalyticsContext';
import useToast from 'apps/agora/src/hooks/useToast';
import { ANALYTICS_EVENT_NAMES } from 'apps/agora/src/utils/constants';
import { isValidWiseField } from 'apps/agora/src/utils/helpers';
import { useEffect } from 'react';
import { useController, useForm } from 'react-hook-form';
import { useQueryClient } from 'react-query';
import { z } from 'zod';
import { ExamFormProps } from '../../types';
import ProfileFormWrapper from '../ProfileFormWrapper';

interface PaymentsTabFormProps extends ExamFormProps {
  mentorData?: MentorData;
}
const checkIsFieldValid = (value: string) =>
  value !== '' && value?.length <= 32 && isValidWiseField(value);

const formDataSchema = z.object({
  billingCurrency: z.string().refine((value) => supportedCurrencies.includes(value), {
    message: 'Please select a valid billing currency.',
  }),

  vatPayer: z.number().min(0, { message: 'Please select a valid option.' }),
  name: z.string().min(1, { message: 'Please enter a valid name.' }),
  address: z.string().min(1, { message: 'Please enter a valid address.' }),
  CUI: z.string().min(1, { message: 'Please enter a valid CUI.' }),
  IBAN: z.string().min(1, { message: 'Please enter a valid IBAN.' }).refine(checkIsFieldValid, {
    message:
      'Company IBAN must be a maximum of 32 characters and contain only valid characters: A-Z, a-z, 0-9, -./?:(),+',
  }),
});

type FormData = z.infer<typeof formDataSchema>;

const PaymentsTabForm = (props: PaymentsTabFormProps) => {
  const { userId, mentorData, setIsEditMode, setIsAddNewItem, onCancel } = props;

  const [showToast] = useToast();
  const query = useQueryClient();

  const { trackEvent } = useAnalytics();

  const {
    register,
    handleSubmit,
    trigger,
    setValue,
    getValues,
    reset,
    control,
    formState: { errors, touchedFields },
  } = useForm<FormData>({
    resolver: zodResolver(formDataSchema),
    reValidateMode: 'onBlur',
    mode: 'onBlur',
    defaultValues: {
      billingCurrency: mentorData?.rate?.billingCurrency,
      vatPayer: mentorData?.rate?.vatPayer ? 1 : 0,
      name: mentorData?.company?.name ?? '',
      address: mentorData?.company?.address ?? '',
      CUI: mentorData?.company?.CUI ?? '',
      IBAN: mentorData?.company?.IBAN ?? '',
    },
  });

  const { field: vatPayerField, fieldState: vatPayerState } = useController({
    name: 'vatPayer',
    control,
  });

  const { field: billingCurrencyField, fieldState: billingCurrencyState } = useController({
    name: 'billingCurrency',
    control,
  });

  const editButtonHandler = () => {
    setIsEditMode?.(false);
    setIsAddNewItem?.(false);
    reset();
  };

  const vatPayerOptions = [
    { label: 'Yes', value: 1 },
    { label: 'No', value: 0 },
  ];

  const { mutate: saveUserProfile, isLoading } = useSaveUserProfile(userId, {
    onSuccess: async () => {
      trackEvent(ANALYTICS_EVENT_NAMES.PROFILE_PAYMENT_CHANGE);
      showToast({
        variant: 'success',
        messageTitle: 'Success',
        messageBody: 'Successfully updated your profile.',
      });
      await query.invalidateQueries('/users/:id/profile');
      editButtonHandler();
    },
    onError: () => {
      showToast({
        variant: 'error',
        messageTitle: 'Error',
        messageBody: 'Profile could not be saved.',
      });
    },
  });

  const onSubmit = async (data: FormData) => {
    const isValid = await trigger();
    if (isValid && mentorData) {
      saveUserProfile({
        rate: {
          ...mentorData.rate,
          billingCurrency: data.billingCurrency,
          vatPayer: data.vatPayer === 1,
        },
        company: {
          name: data.name,
          address: data.address,
          CUI: data.CUI,
          IBAN: data.IBAN,
        },
      });
    }
  };

  const touchAllFields = (fields: Record<keyof FormData, string | number>) => {
    Object.keys(fields).forEach((key) => {
      setValue(key as keyof FormData, getValues()[key as keyof FormData], {
        shouldTouch: true,
        shouldValidate: true,
      });
    });
  };

  const submitHandler = (e: React.MouseEvent<HTMLButtonElement>) => {
    const formData = getValues();

    touchAllFields(formData);

    handleSubmit(onSubmit)(e);
  };

  useEffect(() => {
    if (mentorData?.rate?.amount) {
      const formData = getValues();

      touchAllFields(formData);
    }
  }, []);

  return (
    <ProfileFormWrapper
      title="Payments Settings"
      isModeAddNew={false}
      onCancel={onCancel}
      isLoading={isLoading}
      editButtonHandler={editButtonHandler}
      submitHandler={submitHandler}
    >
      <section className="flex flex-col gap-2">
        <div className="flex flex-col gap-2 tablet:flex-row tablet:gap-6">
          <Input
            type="text"
            id={'payRate'}
            name={'payRate'}
            placeholder={''}
            label={'Pay Rate'}
            value={mentorData?.rate?.amount}
            isDisabled
          />
          <Input
            type="text"
            name={'payRateCurrency'}
            id={'payRateCurrency'}
            label={'Pay Rate Currency'}
            value={mentorData?.rate?.currency}
            isDisabled
          />
        </div>
        <div className="flex flex-col gap-2 tablet:flex-row tablet:gap-6">
          <Select
            options={supportedCurrencies.map((currency) => ({
              label: currency,
              value: currency,
            }))}
            onSelect={billingCurrencyField.onChange}
            onBlur={billingCurrencyField.onBlur}
            value={billingCurrencyField.value}
            size="large"
            id={'billingCurrency'}
            label={'Billing Currency'}
            isRequired
            allowClear={false}
            isTouched={billingCurrencyState.isTouched}
            isValid={!billingCurrencyState.error}
            errorText={billingCurrencyState.error?.message}
          />
          <Select
            options={vatPayerOptions}
            onSelect={vatPayerField.onChange}
            onBlur={vatPayerField.onBlur}
            value={vatPayerField.value}
            size="large"
            id={'vatPayer'}
            label={'I am a VAT Payer'}
            isRequired
            allowClear={false}
            isTouched={vatPayerState.isTouched}
            isValid={!vatPayerState.error}
            errorText={vatPayerState.error?.message}
          />
        </div>
        <Input
          type="text"
          id={'companyName'}
          label={'Company / Freelancer Name'}
          isRequired
          isTouched={touchedFields.name}
          isValid={!errors.name}
          errorText={errors.name?.message}
          {...register('name', {
            required: true,
          })}
        />
        <Input
          type="text"
          id={'address'}
          label={'Company / Freelancer Address'}
          isRequired
          isTouched={touchedFields.address}
          isValid={!errors.address}
          errorText={errors.address?.message}
          {...register('address', {
            required: true,
          })}
        />
        <Input
          type="text"
          id={'CUI'}
          label={'Fiscal Registration Number (or equivalent)'}
          isRequired
          isTouched={touchedFields.CUI}
          isValid={!errors.CUI}
          errorText={errors.CUI?.message}
          {...register('CUI', {
            required: true,
          })}
        />
        <Input
          type="text"
          id={'IBAN'}
          label={'IBAN Number'}
          isRequired
          isTouched={touchedFields.IBAN}
          isValid={!errors.IBAN}
          errorText={errors.IBAN?.message}
          {...register('IBAN', {
            required: true,
          })}
        />
      </section>
    </ProfileFormWrapper>
  );
};

export default PaymentsTabForm;
