import { useEffect } from 'react';

import { useAnalytics } from '../contexts/AnalyticsContext';
import { ANALYTICS_EVENT_NAMES } from '../utils/constants';

const useTrackMountUnmountEvent = (
  mountEvent: ANALYTICS_EVENT_NAMES,
  unmountEvent?: ANALYTICS_EVENT_NAMES
) => {
  const { trackEvent } = useAnalytics();

  useEffect(() => {
    trackEvent(mountEvent);

    return () => {
      if (unmountEvent) {
        trackEvent(unmountEvent);
      }
    };
  }, []);
};

export default useTrackMountUnmountEvent;
